import { Router } from "@solidjs/router";
import { FileRoutes } from "@solidjs/start/router";
import { ErrorBoundary, Suspense } from "solid-js";
import { DottedLoader } from "./widgets/loader";
import "./app.css";
import { ModalProvider } from "~/components/modal";
import { HubbleError } from "~/components/error";
import { MetaProvider } from "@solidjs/meta";
import { isServer } from "solid-js/web";
import "solid-devtools";
import { captureErrorInSentry } from "~/utils/third_party/sentry";
import { config } from "~/data/config";
import { Toaster } from "solid-toast";

export default function HubbleRouter() {
  return (
    <Router
      root={(props) => (
        <ErrorBoundary
          fallback={(err) => {
            if (!isServer) {
              captureErrorInSentry(err);
            }

            if (config.env !== "Prod") {
              console.trace(err);
            }

            return <HubbleError errorMessage={err.message} />;
          }}
        >
          <ModalProvider>
            <MetaProvider>
              <Suspense fallback={<DottedLoader color="#999" />}>
                {props.children}
              </Suspense>
            </MetaProvider>
            <Toaster position="top-center" />
          </ModalProvider>
        </ErrorBoundary>
      )}
    >
      <FileRoutes />
    </Router>
  );
}
