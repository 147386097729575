// @refresh reload
import { mount, StartClient } from "@solidjs/start/client";
import { CSS_FILES } from "~/data/config";
import { CSSFilesWithoutDefault, getCSSFile, getFonts } from "./utils/etc";
import { initSentry } from "~/utils/third_party/sentry";

initSentry();

// Polyfills
if (!Object.hasOwnProperty("hasOwn")) {
  Object.defineProperty(Object, "hasOwn", {
    value: function (obj: any, prop: any) {
      if (obj == null) return false; // null and undefined are special cases
      return Object.prototype.hasOwnProperty.call(obj, prop);
    },
  });
}

if (
  !Array.prototype.hasOwnProperty("findLastIndex") ||
  typeof Array.prototype.findLastIndex !== "function"
) {
  Object.defineProperty(Array.prototype, "findLastIndex", {
    value: function <T>(
      predicate: (arg0: T, arg1: number, arg2: T[]) => boolean
    ) {
      for (let i = this.length - 1; i >= 0; i--) {
        if (predicate(this[i], i, this)) {
          return i;
        }
      }
      return -1;
    },
    writable: true,
    configurable: true,
  });
}

if (typeof String.prototype.replaceAll !== "function") {
  String.prototype.replaceAll = function (
    pattern: string | RegExp,
    replacement: string | ((substring: string, ...args: any[]) => string)
  ) {
    if (typeof pattern === "string") {
      pattern = pattern.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
    }

    const regex = new RegExp(pattern, "g");

    if (typeof replacement === "function") {
      return this.replace(regex, replacement);
    }

    return this.replace(regex, replacement);
  };
}

if (!Array.prototype.at) {
  Array.prototype.at = function (index) {
    index = Math.trunc(index) || 0;

    if (index < 0) {
      index += this.length;
    }

    if (index < 0 || index >= this.length) {
      return undefined;
    }

    return this[index];
  };
}

function loadCSSBasedOnURLParam() {
  const url = new URL(window.location.href);
  const clientId = url.searchParams.get("clientId") || "default";

  const cssFile = getCSSFile(clientId as keyof typeof CSS_FILES);

  const fonts = getFonts("sdk", clientId as keyof CSSFilesWithoutDefault);

  const cssLink = document.createElement("link");
  cssLink.rel = "stylesheet";
  cssLink.href = cssFile;
  document.head.appendChild(cssLink);

  fonts.map((font) => {
    const fontLink = document.createElement("link");
    fontLink.rel = "stylesheet";
    fontLink.href = font;
    return document.head.appendChild(fontLink);
  });
}

loadCSSBasedOnURLParam();

mount(() => <StartClient />, document.getElementById("app")!);
